*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(66 153 225 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(66 153 225 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/* Custom styles */

.ant-picker-cell {
  padding: 0px !important;
  height: 69px !important;
}

.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0) !important;
}

.ant-select-selector {
  border-color: #d8e4f4 !important;
}

.ant-radio-checked {
  border-color: #2a5c96 !important;
}

.ant-radio-checked > .ant-radio-inner {
  background-color: #2a5c96 !important;
  border-color: #2a5c96 !important;
}

.ant-radio-inner {
  border-color: #75889f !important;
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.ant-radio-inner::after {
  background-color: #ffffff !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  margin: -0.75rem !important;
}

.ant-btn {
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.footer-shadow {
  box-shadow: 0px 4px 16px rgba(77, 85, 109, 0.15);
}

.ant-modal-header {
  border-color: #d8e4f4 !important;
}

.ant-modal-footer {
  border-color: #d8e4f4 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important ;
}

.ant-modal-title {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.ant-btn[disabled]:not(.ant-btn-link) {
  border-color: #d9d9d9 !important;
}

.ant-collapse-header {
  padding-left: 2px !important;
  font-weight: 400 !important;
  size: 16px !important;
}

.ant-collapse-content-box {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.confirmed-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(78 170 122 / var(--tw-bg-opacity));
}

.invoiced-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(44 82 130 / var(--tw-bg-opacity));
}

.cancelled-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(255 245 245 / var(--tw-bg-opacity));
}

.preliminary-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(244 165 47 / var(--tw-bg-opacity));
}

.matched-dot {
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(117 136 159 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.potential-dot {
  border-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(117 136 159 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(234 243 250 / var(--tw-bg-opacity));
}

.list-item-w-border {
  border-bottom: solid 1px;
}

.list-item-w-border:first-child {
  border-top: solid 1px;
}

.visible {
  visibility: visible !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-1\.5 {
  margin: 0.375rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.-ml-0\.5 {
  margin-left: -0.125rem !important;
}

.-mr-0\.5 {
  margin-right: -0.125rem !important;
}

.-mt-0\.5 {
  margin-top: -0.125rem !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-2\.5 {
  margin-top: 0.6rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-11 {
  height: 2.75rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5\/6 {
  height: 83.333333% !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-\[120px\] {
  height: 120px !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.max-h-\[150px\] {
  max-height: 150px !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.w-11 {
  width: 2.75rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-full {
  width: 100% !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-md {
  max-width: 28rem !important;
}

.max-w-sm {
  max-width: 24rem !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.grow-default {
  flex-grow: 1 !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-2\.5 {
  gap: 0.6rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-center {
  align-self: center !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.break-words {
  overflow-wrap: break-word !important;
}

.rounded-default {
  border-radius: 0.25rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-b-1 {
  border-bottom-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-t-1 {
  border-top-width: 1px !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-none {
  border-style: none !important;
}

.border-blue-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(144 205 244 / var(--tw-border-opacity)) !important;
}

.border-blue-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(49 130 206 / var(--tw-border-opacity)) !important;
}

.border-blueGrayLight {
  --tw-border-opacity: 1 !important;
  border-color: rgb(234 243 250 / var(--tw-border-opacity)) !important;
}

.border-blueGrayMedium {
  --tw-border-opacity: 1 !important;
  border-color: rgb(216 228 244 / var(--tw-border-opacity)) !important;
}

.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(247 250 252 / var(--tw-border-opacity)) !important;
}

.border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(203 213 224 / var(--tw-border-opacity)) !important;
}

.border-grayLight {
  --tw-border-opacity: 1 !important;
  border-color: rgb(227 229 233 / var(--tw-border-opacity)) !important;
}

.border-primary-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(44 82 130 / var(--tw-border-opacity)) !important;
}

.border-safeLifeLight {
  --tw-border-opacity: 1 !important;
  border-color: rgb(88 131 194 / var(--tw-border-opacity)) !important;
}

.border-safeLifeMedium {
  --tw-border-opacity: 1 !important;
  border-color: rgb(42 92 150 / var(--tw-border-opacity)) !important;
}

.border-warningYellow {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 165 47 / var(--tw-border-opacity)) !important;
}

.border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.bg-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(235 248 255 / var(--tw-bg-opacity)) !important;
}

.bg-blue-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(190 227 248 / var(--tw-bg-opacity)) !important;
}

.bg-blueGrayLight {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 243 250 / var(--tw-bg-opacity)) !important;
}

.bg-blueGrayMedium {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(216 228 244 / var(--tw-bg-opacity)) !important;
}

.bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(237 242 247 / var(--tw-bg-opacity)) !important;
}

.bg-primary-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(44 82 130 / var(--tw-bg-opacity)) !important;
}

.bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 245 245 / var(--tw-bg-opacity)) !important;
}

.bg-red-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 129 129 / var(--tw-bg-opacity)) !important;
}

.bg-safeLifeDark {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 72 125 / var(--tw-bg-opacity)) !important;
}

.bg-safeLifeMedium {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(42 92 150 / var(--tw-bg-opacity)) !important;
}

.bg-successLight {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(235 250 243 / var(--tw-bg-opacity)) !important;
}

.bg-warning {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 73 81 / var(--tw-bg-opacity)) !important;
}

.bg-warningBG {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 246 234 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 240 / var(--tw-bg-opacity)) !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pr-\[4\.5rem\] {
  padding-right: 4.5rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-1\.5 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.text-center {
  text-align: center !important;
}

.align-middle {
  vertical-align: middle !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.leading-9 {
  line-height: 2.25rem !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-blue-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(44 82 130 / var(--tw-text-opacity)) !important;
}

.text-blueGrayDark {
  --tw-text-opacity: 1 !important;
  color: rgb(84 91 111 / var(--tw-text-opacity)) !important;
}

.text-blueGrayMediumDark {
  --tw-text-opacity: 1 !important;
  color: rgb(117 136 159 / var(--tw-text-opacity)) !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(160 174 192 / var(--tw-text-opacity)) !important;
}

.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(113 128 150 / var(--tw-text-opacity)) !important;
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 85 104 / var(--tw-text-opacity)) !important;
}

.text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(45 55 72 / var(--tw-text-opacity)) !important;
}

.text-red-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(155 44 44 / var(--tw-text-opacity)) !important;
}

.text-safeLifeDark {
  --tw-text-opacity: 1 !important;
  color: rgb(37 72 125 / var(--tw-text-opacity)) !important;
}

.text-success {
  --tw-text-opacity: 1 !important;
  color: rgb(78 170 122 / var(--tw-text-opacity)) !important;
}

.text-warningYellow {
  --tw-text-opacity: 1 !important;
  color: rgb(244 165 47 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-yellow-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(151 90 22 / var(--tw-text-opacity)) !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition-all {
  transition-property: all !important;
}

.duration-200 {
  transition-duration: 200ms !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.hover\:border-primary-700:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(43 108 176 / var(--tw-border-opacity)) !important;
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(237 242 247 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-primary-700:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(43 108 176 / var(--tw-bg-opacity)) !important;
}

.focus\:border-primary-900:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgb(42 67 101 / var(--tw-border-opacity)) !important;
}

.focus\:bg-primary-900:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(42 67 101 / var(--tw-bg-opacity)) !important;
}

.focus\:opacity-50:focus {
  opacity: 0.5 !important;
}

@media (min-width: 640px) {
  .sm\:max-w-xs {
    max-width: 20rem !important;
  }

  .sm\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .md\:h-\[260px\] {
    height: 260px !important;
  }
}

@media (min-width: 1024px) {
  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
